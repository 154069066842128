function recaptchaCallback() {
    $('#f_recaptcha').valid();
}

 function heightConfiguratorStep(){
    $('.etape').each(function(){
        if($(this).hasClass('active')){
            var height = $(this).parent().find('.row').outerHeight(true);
            $(this).css('height', height);
        } else {
            $(this).css('height', '0');
        }
    });
}

//SCROLL MENU

$(".eachMenu").click(function() {
    // récupérer la hauteur du form
    sous_menuHeight = $(this).find('.submenu').outerHeight(true) + 70;

    // Si eachMenu à la classe active alors
    if($(this).hasClass('active')) {
        // on enlève la class active de eachMenu
        $('.eachMenu').removeClass('active');
        // et sa taille est de 70 (taille du texte)
        $('.eachMenu').css('height', 70);
    // Sinon
    } else {
        // et sa taille est de 70 (taille du texte)
        $('.eachMenu').css('height', 70);
        $('.eachMenu').removeClass('active');
        // on ajoute la class active à eachMenu
        $(this).addClass('active');
        // et sa classe prend la taille de l'élément
        $(this).css('height', sous_menuHeight);
    }
})


/// FILE INTO INPUT CONFIGURATOR BAG RECTO

$('.versoActivate').click(function(){
    $(this).addClass('active');
    $('.rectoActivate').removeClass('active');
    $('.logoContainerV').addClass('active');
    $('.versoCont').addClass('active');
    $('.logoContainerR').removeClass('active');
    $('.rectoCont').removeClass('active');
});

$('.rectoActivate').click(function(){
    $(this).addClass('active');
    $('.versoActivate').removeClass('active');
    $('.logoContainerR').addClass('active');
    $('.rectoCont').addClass('active');
    $('.logoContainerV').removeClass('active');
    $('.versoCont').removeClass('active');
});



function readURL(input) {


    if (input.files && input.files[0]) {


        if(input.classList[0] == 'recto'){


            var reader = new FileReader();

            reader.onload = function (e) {
                $('.rectoContainer').find('.previewImage').attr('src', e.target.result);
                if( input.files.length == 0 ){
                    $('.rectoCont').find('.sizePickerContainer').removeClass('visible');
                    $('.rectoCont').find('.deleteLogo').removeClass('visible');

                } else {
                    $('.rectoCont').find('.sizePickerContainer').addClass('visible');
                    $('.rectoCont').find('.deleteLogo').addClass('visible');
                }
            };

            reader.readAsDataURL(input.files[0]);
            $('.rectoCont').find(".file-return").html(input.files[0].name);

            setTimeout(function(){
                var height = $('.rectoContainer').find('.grabbable').find('img').outerHeight(true);
                $('.rectoContainer').find('.grabbable').css('height', height);
            }, 150);

        } else {
            var reader = new FileReader();

            reader.onload = function (e) {
                $('.versoContainer').find('.previewImage').attr('src', e.target.result);
                if( input.files.length == 0 ){
                    $('.versoCont').find('.sizePickerContainer').removeClass('visible');
                    $('.versoCont').find('.deleteLogo').removeClass('visible');

                } else {
                    $('.versoCont').find('.sizePickerContainer').addClass('visible');
                    $('.versoCont').find('.deleteLogo').addClass('visible');
                }
            };

            reader.readAsDataURL(input.files[0]);
            $('.versoCont').find(".file-return").html(input.files[0].name);

            setTimeout(function(){
                var height = $('.versoContainer').find('.grabbable').find('img').outerHeight(true);
                $('.versoContainer').find('.grabbable').css('height', height);
            }, 150);

        }
    }
}

// initialisation des variables
var fileInputR  = $('.rectoCont').find( ".input-file" ),
    buttonR    = $('.rectoCont').find( ".input-file-trigger" ),
    the_returnR = $('.rectoCont').find(".file-return");

// action lorsque la "barre d'espace" ou "Entrée" est pressée
buttonR.keydown(function( event ) {
    if ( event.keyCode == 13 || event.keyCode == 32 ) {
        fileInputR.focus();
    }
});

// action lorsque le label est cliqué
buttonR.click(function( event ) {
   fileInputR.focus();
});

 $('.rectoContainer').find('.logoSacPerso').draggable({
     containment : '.rectoContainer'
 });




// SIZE PICKER

$('.rectoCont').find( ".sizePickerR" ).slider({
    range: "max",
    min: 10,
    max: 100,
    value: 100,

    slide: function( event, ui ) {
        $('.rectoCont').find( "#total" ).val( ui.value  + '%' );
        $('.rectoContainer').find('.logoSacPerso').css('width', ui.value + '%')
        var height = $('.logoContainerR').find('.grabbable').find('img').outerHeight(true);
        $('.logoContainerR').find('.grabbable').css('height', height);
        // $('.logo').css('height', ui.value + '%')
        $('.logoContainerR').find('.logoSacPerso').css('inset', '0 auto auto 0');
    }
});

$('.rectoCont').find( "#total" ).val( $( ".sizePickerR" ).slider( "value" )  + '%');

$('.rectoCont').find('.deleteLogo').click(function(){
    fileInputR.value = "";
    $('.rectoContainer').find('.previewImage').attr('src', '');
    $('.rectoCont').find('.sizePickerContainer').removeClass('visible');
    $('.rectoCont').find('.deleteLogo').removeClass('visible');
    $('.rectoCont').find('.file-return').html('');
});

fileInputR.value = "";


/// FILE INTO INPUT CONFIGURATOR BAG VERSO


// initialisation des variables
var fileInputV  = $('.versoCont').find( ".input-file" ),
    buttonV    = $('.versoCont').find( ".input-file-trigger" ),
    the_returnV = $('.versoCont').find(".file-return");

// action lorsque la "barre d'espace" ou "Entrée" est pressée
buttonV.keydown(function( event ) {
    if ( event.keyCode == 13 || event.keyCode == 32 ) {
        fileInputV.focus();
    }
});

// action lorsque le label est cliqué
buttonV.click(function( event ) {
   fileInputV.focus();
});

$('.versoContainer').find('.logoSacPerso').draggable({
    containment : '.versoContainer'
});

// SIZE PICKER

$('.versoCont').find( ".sizePickerV" ).slider({
    range: "max",
    min: 10,
    max: 100,
    value: 100,

    slide: function( event, ui ) {
        $('.versoCont').find( "#total" ).val( ui.value  + '%' );
        $('.versoContainer').find('.logoSacPerso').css('width', ui.value + '%')
        var height = $('.logoContainerV').find('.grabbable').find('img').outerHeight(true);
        $('.logoContainerV').find('.grabbable').css('height', height);
        // $('.logo').css('height', ui.value + '%')
        $('.logoContainerV').find('.logoSacPerso').css('inset', '0 auto auto 0');
    }
});

$('.versoCont').find( "#total" ).val( $( ".sizePickerV" ).slider( "value" )  + '%');

$('.versoCont').find('.deleteLogo').click(function(){
    fileInputV.value = "";
    $('.versoContainer').find('.previewImage').attr('src', '');
    $('.versoCont').find('.sizePickerContainer').removeClass('visible');
    $('.versoCont').find('.deleteLogo').removeClass('visible');
    $('.versoCont').find('.file-return').html('');
});

fileInputV.value = "";














$(document).ready(function() {

    $('#reg_same_address').on('click', function() {
        if ($(this).prop("checked") === true) {
            $('.shipping_register').css('display', 'none');
            $('.shipping_register input').each(function() {
                $(this).prop("disabled", true);
            })
            $('#reg_shipping_dep').prop("disabled", true);
        } else {
            $('.shipping_register').css('display', 'block');
            $('.shipping_register input').each(function() {
                $(this).prop("disabled", false);
            });
            $('#reg_shipping_dep').prop("disabled", false);
        }
    });

    $('.toggleMenu').click(function(){
        $('nav').toggleClass('menuOpen');
        $('.toggleMenu').toggleClass('menuOpen');
    });

    // CAROUSEL HOMEPAGE
    $('#sliderHomepage').owlCarousel({
        loop: true,
        margin: 0,
        nav: true,
        navText: ["", "<div class='arrow'>→</div>"],
        dot: false,
        items: 1
    });

    // CAROUSEL PRODUCTS
    $('#sliderProducts').owlCarousel({
        loop: true,
        margin: 0,
        nav: true,
        navText: ["", "<div class='arrow'>→</div>"],
        dot: false,
        items: 1
    });

    // CAROUSEL AVANTAGES SURFILM

    $('#avantages').owlCarousel({
        loop:true,
        margin:10,
        navText: ["←", "→"],
        responsiveClass: true,
        dot: false,
        responsive:{
            0:{
              nav: true,
              navText: ["←", "→"],
              items:1
            },
            600:{
              nav: true,
              navText: ["←", "→"],
              items:2
            },
            700:{
              nav: true,
              navText: ["←", "→"],
              items:3
            },
            1000:{
              nav: false,
              mouseDrag: false,
              touchDrag: false,
              pullDrag: false,
              freeDrag: false,
              items:5
            }
        }
    })

    $.validator.addMethod("validateRecaptcha", function (value, element) {
          if (grecaptcha.getResponse() == '') {
              return false;
          } else {
              return true;
          }
      }, "Vous devez valider le reCAPTCHA");

    setTimeout(function () {
      if ($('#contact_form').length) {
          $('#rgpd').rules('add', {
                validateCgv: true
            });
          $('#f_recaptcha').rules('add', {
              validateRecaptcha: true
          });
        }
    }, 100);

    $.extend($.validator.messages, {
        required: "Ce champ est obligatoire.",
        email: "Veuillez fournir une adresse électronique valide."
    });
    $.validator.addMethod("validateCgv", function (value, element) {
            return $('#rgpd').is(':checked');
        }, "Vous devez avoir pris connaissance de notre politique de confidentialité");

    $('#contact_form').validate({
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.addClass("help-block");
            if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                var parentElm = element.closest('.checkboxContainer');
                error.addClass("checkbox");
                parentElm.append(error);
            }
            else
                error.insertAfter(element);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
        },
        submitHandler: function (form) {
            form.submit();
        }
    });

    $('#config_form').validate({
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.addClass("help-block");
            if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                var parentElm = element.closest('.checkboxContainer');
                error.addClass("checkbox");
                parentElm.append(error);
            }
            else
                error.insertAfter(element);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
        },
        submitHandler: function (form) {
            form.submit();
        }
    });



    // CAROUSEL DETAILS PRODUCTS
    $(document).ready(function() {

      var sync1 = $("#sync1");
      var sync2 = $("#sync2");
      var slidesPerPage = 12; //globaly define number of elements per page
      var syncedSecondary = true;

      sync1.owlCarousel({
        items : 1,
        slideSpeed : 2000,
        nav: false,
        dots: false,
        loop: false,
        animateOut: 'fadeOut',
        responsiveRefreshRate : 200,
      }).on('changed.owl.carousel', syncPosition);

      sync2
        .on('initialized.owl.carousel', function () {
          sync2.find(".owl-item").eq(0).addClass("current");
        })
        .owlCarousel({
        items : 3,
        //center: true,
        nav: false,
        loop: false,
        dots: false,
        mouseDrag: false,
        pullDrag: false,
        touchDrag: false,
        freeDrag: false,
        smartSpeed: 200,
        slideSpeed : 500,
        slideBy: slidesPerPage, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
        responsiveRefreshRate : 100,
      }).on('changed.owl.carousel', syncPosition2);

      function syncPosition(el) {
        //if you set loop to false, you have to restore this next line
        var current = el.item.index;

        //if you disable loop you have to comment this block
        // var count = el.item.count-1;
        // var current = Math.round(el.item.index - (el.item.count/2) - .5);

        // if(current < 0) {
        //   current = count;
        // }
        // if(current > count) {
        //   current = 0;
        // }

        //end block

        sync2
          .find(".owl-item")
          .removeClass("current")
          .eq(current)
          .addClass("current");
        var onscreen = sync2.find('.owl-item.active').length - 1;
        var start = sync2.find('.owl-item.active').first().index();
        var end = sync2.find('.owl-item.active').last().index();

        if (current > end) {
          sync2.data('owl.carousel').to(current, 100, true);
        }
        if (current < start) {
          sync2.data('owl.carousel').to(current - onscreen, 100, true);
        }
      }

      function syncPosition2(el) {
        if(syncedSecondary) {
          var number = el.item.index;
          sync1.data('owl.carousel').to(number, 100, true);
        }
      }

      sync2.on("click", ".owl-item", function(e){
        e.preventDefault();
        var number = $(this).index();
        sync1.data('owl.carousel').to(number, 300, true);
        sync2.data('owl.carousel').to(number, 300, true);
      });




    });



    if ($('body').hasClass('configurator')) {

        //Color Picker


        $('.colorValid').click(function(){
            $('.colorValid').removeClass('active');
            $(this).addClass('active');
            var r = $(this).data('r');
            var g = $(this).data('g');
            var b = $(this).data('b');
            $( "#red" ).slider( "value", r);
            $( "#green" ).slider( "value", g);
            $( "#blue" ).slider( "value", b);
            refreshSwatch();
        });

        function hexFromRGB(r, g, b) {

            var hex = [
                r.toString( 16 ),
                g.toString( 16 ),
                b.toString( 16 )
            ];

            $.each( hex, function( nr, val ) {

                if ( val.length === 1 ) {
                    hex[ nr ] = "0" + val;
                }

            });

            return hex.join( "" ).toUpperCase();
        }

        function hexToRgb(hex){

            hex = hex.replace('#','');
            r = parseInt(hex.substring(0,2), 16);
            g = parseInt(hex.substring(2,4), 16);
            b = parseInt(hex.substring(4,6), 16);

            var hexArray = [r, g, b];
            return hexArray;
        }


        $('.toggleColorPicker').click(function(){

            $('.colorPicker').addClass('active');
            $(this).addClass('active');

            var height = $('.etape3').find('.row').outerHeight(true);
            $('.etape3').css('height', height + 195);
        });


        $('#red, #green, #blue').click(function(){
            $('.colorValid').removeClass('active');
        });

        function refreshSwatch() {

            var red = $( "#red" ).slider( "value" ),
            green = $( "#green" ).slider( "value" ),
            blue = $( "#blue" ).slider( "value" );

            var hex = hexFromRGB( red, green, blue );

            $( ".bloc" ).css( "background-color", "#" + hex );

            $('#hexInput').val('#' + hex);

            $('#red.ui-slider').css('background', 'linear-gradient(90deg, rgb(' + 0 + ',' + green + ',' + blue + '), rgb(' + 255 + ',' + green + ',' + blue + '))');
            $('#green.ui-slider').css('background', 'linear-gradient(90deg, rgb(' + red + ',' + 0 + ',' + blue + '), rgb(' + red + ',' + 255 + ',' + blue + '))');
            $('#blue.ui-slider').css('background', 'linear-gradient(90deg, rgb(' + red + ',' + green + ',' + 0 + '), rgb(' + red + ',' + green + ',' + 255 + '))');

            // HSP (Highly Sensitive Poo) equation from   http://alienryderflex.com/hsp.html

            hsp = Math.sqrt(
                0.299 * (red * red) +
                0.587 * (green * green) +
                0.114 * (blue * blue)
            );

            // Using the HSP value, determine whether the color is light or dark

            if (hsp>127.5) {

                $('.bloc').addClass('dark');
                $('.bloc').removeClass('light');

            } else {

                $('.bloc').removeClass('dark');
                $('.bloc').addClass('light');

            }

            $( ".side" ).css( "fill", "#" + hex );
            $( ".notSide" ).css( "fill", "#" + hex );
            $( ".phantom" ).css( "fill", "#" + hex );
        }

        $(function() {

            $( "#red, #green, #blue" ).slider({
                orientation: "horizontal",
                range: "min",
                min: 0,
                max: 255,
                value: 255,
                slide: refreshSwatch,
                change: refreshSwatch
            });

            $( "#red" ).slider( "value", 250 );
            $( "#green" ).slider( "value", 250 );
            $( "#blue" ).slider( "value", 250 );


        });

        $('#hexInput').change(function(){

            var newValHex = $('#hexInput').val();
            var newRGB = hexToRgb(newValHex);

            $( ".bloc" ).css( "background-color", "#" + newValHex );
            $( "#red" ).slider( "value", newRGB[0] );
            $( "#green" ).slider( "value", newRGB[1] );
            $( "#blue" ).slider( "value", newRGB[2] );

            $('.colorValid').removeClass('active');

        });

        function adjustTextColor() {

            // Call lightOrDark function to get the brightness (light or dark)
            brightness = lightOrDark(bgColor);

            // If the background color is dark, add the light-text class to it
            if(brightness == 'dark') {
                element.addClass('light-text');
                element.removeClass('dark-text');
            } else {
                element.addClass('dark-text');
                element.removeClass('light-text');
            }
        }
        // Configurator JS
        // --------------------------------------



        // Nav
        $('.etapeNav').click(function(){
            var step = $(this).data('step');
            $('.configAll').find('.etape').removeClass('active');
            $('.configAll').find('.etape').eq(step).addClass('active');

            heightConfiguratorStep();
        });

        heightConfiguratorStep();
        $(window).resize(heightConfiguratorStep);


        // CHOIX SAC

        $('.etape1Container').find('.card').click(function(){
            $('.card').removeClass('active');
            $(this).addClass('active');
        });

        $('.etape1').find('.productsCards').click(function(){
            $('.etape1').removeClass('active');
            $('.etape2').addClass('active');
            heightConfiguratorStep();
        });

        // RANGE MENSURATION

        // Largeur

        var slider1 = document.getElementById("largeur");
        var output1 = document.getElementById("largeurValue");
        var max = slider1.max;
        var min = slider1.min;

        output1.value = slider1.value;

        slider1.oninput = function() {
            output1.value = this.value;
        } ;

        output1.oninput = function() {
            slider1.value = this.value;
        };

        $(document).on("focusout",output1,function(){
            output1.value = slider1.value;
        });

        // Hauteur

        var slider2 = document.getElementById("hauteur");
        var output2 = document.getElementById("hauteurValue");
        var max = slider2.max;
        var min = slider2.min;

        output2.value = slider2.value;

        slider2.oninput = function() {
            output2.value = this.value;
        } ;

        output2.oninput = function() {
            slider2.value = this.value;
        };

        $(document).on("focusout",output2,function(){
            output2.value = slider2.value;
        });

        // Soufflet

        var slider3 = document.getElementById("soufflet");
        var output3 = document.getElementById("souffletValue");
        var max = slider3.max;
        var min = slider3.min;

        output3.value = slider3.value;

        slider3.oninput = function() {
            output3.value = this.value;
        } ;

        output3.oninput = function() {
            slider3.value = this.value;
        };

        $(document).on("focusout",output3,function(){
            output3.value = slider3.value;
        });

        // Soufflet

        var slider4 = document.getElementById("epaisseur");
        var output4 = document.getElementById("epaisseurValue");
        var max = slider4.max;
        var min = slider4.min;

        output4.value = slider4.value;

        slider4.oninput = function() {
            output4.value = this.value;
        } ;

        output4.oninput = function() {
            slider4.value = this.value;
        };

        $(document).on("focusout",output4,function(){
            output4.value = slider4.value;
        });




    }
    // ---------------------------------------
});
